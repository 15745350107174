import { Component, Input, OnInit } from '@angular/core';
import { CallSchedulingService, ScheduledCall } from 'src/app/services/call-scheduling.service';
import { AnimationOptions } from 'ngx-lottie';
import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mm-scheduled-meeting',
  templateUrl: './scheduled-meeting.component.html',
  styleUrls: ['./scheduled-meeting.component.scss'],
})
export class ScheduledMeetingComponent implements OnInit {
  @Input() call: ScheduledCall;
  options: AnimationOptions;

  constructor(
    private callSchedulingService: CallSchedulingService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.options = {
      path: '/assets/welcome/slide-3.json',
    };
  }

  closeMeetingModal = this.callSchedulingService.closeMeetingModal;

  public formatMeetingDateTime(timeSlot: string): string {
    const locale = this.translateService.currentLang === 'es' ? es : enUS;
    return `${format(new Date(timeSlot), "EEEE, MMMM d, y 'at' h:mm aa", { locale })}`;
  }
}
