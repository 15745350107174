import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  constructor(private translate: TranslateService) {}

  async initializeTranslations() {
    const defaultLanguage = 'en';
    const { language: browserLanguage } = navigator;
    console.log('Browser Language: ', browserLanguage);

    if (!browserLanguage) {
      return this.translate.use(defaultLanguage);
    }

    const languageToUse = environment.supportedLanguages.includes(browserLanguage.split('-')[0])
      ? browserLanguage.split('-')[0]
      : defaultLanguage;

    return this.translate.use(languageToUse);
  }
}
