import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { PreviewAnyFile } from '@awesome-cordova-plugins/preview-any-file/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MarkdownModule } from 'ngx-markdown';
import player from 'lottie-web';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { quickPageAnimation } from 'src/app/animations';
import { AuthInterceptor } from 'src/app/interceptors/auth-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertModule } from './components/alert/alert.module';
import { UpgradePageModule } from './pages/upgrade/upgrade.module';
import { AuthService } from './services/auth.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

const appInitFactory =
  (auth: AuthService): (() => Promise<void>) =>
  async () => {
    await auth.initialize();
    await auth.isAuthenticated();
  };

export const HttpLoaderFactory = (http: HttpClient) => {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
};

registerLocaleData(localeEn, 'en');
registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: false,
      navAnimation: quickPageAnimation,
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AlertModule,
    LottieModule.forRoot({ player: () => player }),
    LottieCacheModule.forRoot(),
    UpgradePageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MarkdownModule.forRoot(),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PreviewAnyFile,
    { provide: APP_INITIALIZER, useFactory: appInitFactory, deps: [AuthService], multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // init tracer
  constructor() {}
}
