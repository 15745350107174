import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { format, parseISO } from 'date-fns';
import { delay, firstValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';

export const onServiceError = (errResponse: HttpErrorResponse): string => {
  let errorMessage = '';
  if (errResponse.error instanceof ErrorEvent) {
    errorMessage = errResponse.error.message;
  } else {
    errorMessage = `Error Code: ${ errResponse.status }\nMessage: ${ errResponse.message }`;
  }

  throw new Error(errorMessage);
};

export const removeKeys = (v, keyName) => Object.keys(v).filter( key => key!==keyName).reduce( (obj, key) => {obj[key] = v[key]; return obj}, {} );

export const formatDate = (value: string, formatStyle: string) => {
  const d = new Date(value);
  return format(parseISO(d.toISOString()), formatStyle);
}

export const createDelay = (ms: number) => firstValueFrom(of(null).pipe(delay(ms)))
export const createSaveDelay = () => createDelay(environment.saveDelay);


export const nowMinusTzOffset = ()=>{
  const date = new Date();
  const offset = date.getTimezoneOffset()/60;
  return new Date(date.setHours( date.getHours() - offset));
};

export function getFileSizeFromBytes(size: number): string {
  console.log("**** getFileSizeFromBytes: size", size);
  if(!size) { return ''} // BUGFIX for 'NaN MB' filesize issue with Camera files (no filesize available)

  const stringSize = size+"";
  let value = "";
  if(stringSize.length > 3 && stringSize.length < 7){
    value = Math.ceil(size/1024) + "KB";
  }
  if(stringSize.length > 6 && stringSize.length < 10){
    value = Math.ceil(size/(1024*1024)) + "MB";
  }
  if(stringSize.length > 9 && stringSize.length < 13){
    value = Math.ceil(size/(1024*1024*1024)) + "GB";
  }
  return value;
}

export const getTruncatedFilenameWithExtension = (fileNameWithExtension: string, limit?): string => {
  if (!fileNameWithExtension) { return ''; }

  const lim = limit || 25;
  const fileNameArray = fileNameWithExtension?.split('.');
  const shortName = fileNameArray[0]?.length > lim ? fileNameArray[0]?.substring(0, lim) + '...' : fileNameArray[0];
  return `${ shortName }.${ fileNameArray[1]?.toLowerCase() }`;
};


export const getFileExtension = (fileName): string => fileName?.split('.')?.reverse()[0]?.toLowerCase();

// bug: needed in order to make `FileReader.onload` fire
export const getFileReader = (): FileReader => {
  const fileReader = new FileReader();
  // eslint-disable-next-line no-underscore-dangle
  const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
  return zoneOriginalInstance || fileReader;
};


export function compressBase64ImageByHeight(src, newY) {
  return new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      console.warn('IMG x,y:', img.width, img.height);
      const ratio = img.height / img.width;

      const elem = document.createElement('canvas');
      const newX = Math.round(newY / ratio);
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    }
    img.onerror = error => rej(error);
  })
}

/* TODO: remove other implementations */
export function convertBlobToBase64(blob?: Blob): Promise<any> {
  if (!blob) return Promise.resolve(null);

  return new Promise((resolve, reject) => {
    const reader = getFileReader();
    reader.onerror = reject;
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

// TODO: find & remove duplicate implementations
export function isWeb() {
  return  Capacitor.getPlatform() === 'web';
}

const closeVideo = (videoElement: HTMLMediaElement, videoContainer): void => {
  videoElement.addEventListener('ended', () => {
    if (videoElement['webkitEnterFullscreen']) { // iOS (native and web Safari) video ends, close it automatically
      videoElement['webkitExitFullscreen']();
      videoContainer.nativeElement.removeChild(videoElement);
    } else if (document.fullscreenElement) { // non-iOS video ends, close it automatically
      document.exitFullscreen();
      videoContainer.nativeElement.removeChild(videoElement);
    }
  });
}

export const playVideo = (videoSrc: string, posterSrc: string, videoContainer: ElementRef): void => {
  const videoElement = document.createElement('video');
  videoElement.poster = posterSrc;
  videoElement.controls = true;
  videoElement.preload = 'metadata';

  videoElement.innerHTML = `<source src="${videoSrc}" type="video/mp4">`;

  videoContainer.nativeElement.appendChild(videoElement);

  // for ios native and web Safari
  if (videoElement['webkitEnterFullscreen']) {
    console.log('webkitEnterFullscreen available');
    videoElement.autoplay = true;
    videoElement.addEventListener(
      'loadedmetadata',
      function () {
        try {
          videoElement['webkitEnterFullscreen']();
          console.log('webkitEnterFullscreen called');
        } catch (error) {
          console.error('Error entering fullscreen:', error);
        }
      },
      false
    );
  } else { // for non-iOS
    videoElement.autoplay = true;
    videoElement.requestFullscreen();
  }

  // non-iOS user exits fullscreen
  videoElement.addEventListener('fullscreenchange', () => {
    console.log('fullscreenchange', document);
    if (!document.fullscreenElement) {
      videoContainer.nativeElement.removeChild(videoElement);
    }
  });

  // iOS (native and web Safari) user exits fullscreen
  videoElement.addEventListener('webkitendfullscreen', () => {
    console.log('Exited fullscreen via webkitendfullscreen');
    videoContainer.nativeElement.removeChild(videoElement);
  });

  closeVideo(videoElement, videoContainer);
}
