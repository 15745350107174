import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular'; //import { Storage } from '@capacitor/storage';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, shareReplay, tap } from 'rxjs';
import { NotificationNames } from './notification.service';

const NOTIFICATION_TOGGGLES_STORAGE_KEY='notification-toggles'


export interface NotificationSettings {
  messages: boolean;
  caseStatus: boolean;
  painLevel: boolean;
  treatments: boolean; //SHOULD BE: treatmentScheduled
  onboardingReminder: boolean;
  callRescheduled: boolean;
  tasks: boolean;

  days: boolean[];  //7 days / 7 booleans
}


/**
 * ********************************************************************************************************************************************
 * ********************************************************************************************************************************************
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationSettingsService { //implements INotificationSettingService {
  /**
   * *******************
   * MEMBERS
   * *******************
   */
  private messageState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private caseStatusState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private painLevelState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private treatmentScheduledState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private onboardingReminderState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private callRescheduledState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tasksState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private daysOfWeekState$: BehaviorSubject<boolean[]> = new BehaviorSubject<boolean[]>([false, false, false, false, false, false, false]);

  public toggleStates$: Observable<NotificationSettings> = 
    combineLatest([
      this.messageState$,
      this.caseStatusState$,
      this.painLevelState$,          //no toggle
      this.treatmentScheduledState$, //no toggle
      this.onboardingReminderState$, //no toggle
      this.callRescheduledState$,    //no toggle
      this.tasksState$,

      //Not a notification, but options for painLevelState when true
      this.daysOfWeekState$,
    ])
    .pipe(
      // skip(1),
      map( ([
              messages, caseStatus, painLevel, treatments, tasks, onboardingReminder, callRescheduled,
              days
      ]):NotificationSettings => ({
          messages,
          caseStatus,
          painLevel,
          treatments,
          onboardingReminder,
          callRescheduled,
          tasks,

          days,
        })
      ),
      // tap( (states: NotificationSettings) => console.log('toggleStates$:', states) ),
      tap( async (states: NotificationSettings) => await this.storeNotificationToggleStates(states) ),
      shareReplay(1),
  );



  

  /**
   * *******************
   * METHODS
   * *******************
   */
  constructor(
    private storage: Storage,
  ) {
    this.loadNotificationToggleStates().then(
      (state: NotificationSettings) => {
        if(state) {
          console.log('Notification Settings ARE in storage');
          this.messageState$.next(state.messages===undefined ? true : state.messages);
          this.caseStatusState$.next(state.caseStatus===undefined ? true : state.caseStatus);
          this.painLevelState$.next(state.painLevel===undefined ? true : state.painLevel);
          this.treatmentScheduledState$.next(state.treatments===undefined ? true : state.treatments);
          this.onboardingReminderState$.next(state.onboardingReminder===undefined ? true : state.onboardingReminder);
          this.callRescheduledState$.next(state.callRescheduled===undefined ? true : state.callRescheduled);
          this.tasksState$.next(state.tasks===undefined ? true : state.tasks);

          try {
            this.setPainLevelDays(state.days);
          } catch(e) {
            console.log('unable to assign settings for all 7 days of the week', e);
          }
        } else {
          console.log('Notification Settings are NOT in storage');
          // Kick it off with default settings
          this.messageState$.next(true);
          this.caseStatusState$.next(true);
          this.painLevelState$.next(true);
          this.treatmentScheduledState$.next(true);
          this.onboardingReminderState$.next(true);
          this.callRescheduledState$.next(true);
          this.tasksState$.next(true);

          this.setPainLevelDays([
            true, //Sunday
            true, //Monday
            true, //Tuesday
            true, //Wednesday
            true, //Thursday
            true, //Friday
            true  //Saturday
          ]);
      }
    })
  }

  setPainLevelDays( daysOfWeek: boolean[] ) {
    if(daysOfWeek.length==7) {
      this.daysOfWeekState$.next( daysOfWeek )
    }
    else {
      throw('setPainLevelDays(): 7 booleans are required in this array - one for each day of the week');
    }
  }


  private async loadNotificationToggleStates() {
    await this.storage.create();
    return this.storage.get(NOTIFICATION_TOGGGLES_STORAGE_KEY);
  }

  private async storeNotificationToggleStates(states?: NotificationSettings) { //either pass in a value or use latest
    return this.storage.set(NOTIFICATION_TOGGGLES_STORAGE_KEY, states ?? firstValueFrom(this.toggleStates$));
  }


  flipToggle(option: NotificationNames) {
    switch (option) {
      case 'messages':
        this.messageState$.next( !this.messageState$.getValue() );
        break;
      case 'caseStatus':
        this.caseStatusState$.next( !this.caseStatusState$.getValue() );
        break;
      case 'tasks':
        this.tasksState$.next( !this.tasksState$.getValue() );
        break;

      //Currently NO TOGGLE SWITCHES for the following:
      case 'painLevel':
        this.painLevelState$.next( !this.painLevelState$.getValue() );
        break;
      case 'treatments':
        this.treatmentScheduledState$.next( !this.treatmentScheduledState$.getValue() );
        break;
      case 'onboardingReminder':
        this.onboardingReminderState$.next( !this.onboardingReminderState$.getValue() );
        break;
      case 'callRescheduled':
        this.callRescheduledState$.next( !this.callRescheduledState$.getValue() );
        break;
      default:
        break;
    }
  }
}
