import { Inject, Injectable, InjectionToken } from '@angular/core';
import { isPlatform, Platform } from '@ionic/angular';
import { BehaviorSubject, distinctUntilChanged, filter, from, map, Observable, switchMap } from 'rxjs';

import jwt_decode from 'jwt-decode';
import { Auth0Provider as BaseAuth0Provider, AuthConnect, AuthProvider, AuthResult, ProviderOptions, AuthConnectConfig, Manifest } from '@ionic-enterprise/auth';
import { SessionStorageInterface, VaultService } from '../services/vault.service';
import { createDelay } from './util';

import { environment } from 'src/environments/environment';
const { api, auth0 } = environment;


const getQueryParams = (): Map<string, string> => {
  const query = window.location.search.substring(1);
  const params = new Map<string, string>();
  const pairs = query.split('&');
  for (const pair of pairs) {
    const [key, value] = pair.split('=');
    if (key) {
      params.set(decodeURIComponent(key), decodeURIComponent(value || ''));
    }
  }
  return params;
}

export interface JWT {
  sub: string;
  aad_id: string;
  aud: string;
  matter_id: string;
  account_id: string;
  // salesforceId: string;
  exp: number;
}

export interface AuthState {
  jwt: JWT | null;
  token: string;
}

export interface ProviderOptionsPlatforms {
  web: ProviderOptions;
  native: ProviderOptions;
  webPopup: {
    uiMode: string;
    authFlow: string;
  },
}



/*
export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage
});
*/
/*
export const SESSION_STORAGE = new InjectionToken<SessionStorageInterface>('Identity Session Storage', {
  providedIn: 'root',
  factory: () => (new VaultService())
});
*/
export const AUTH_0 = new InjectionToken<ProviderOptions>('Auth0 Config', {
  providedIn: 'root',
  factory: () =>  isPlatform('hybrid') ? auth0.native : auth0.web
});



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // private auth0 = auth0;
  /**
   * *********************************************************************************
   * MEMBERS *************************************************************************
   * *********************************************************************************
   */
  private provider = new Auth0Provider();
  private result: AuthResult | null = null;
  private authResult: AuthResult | null = null;

  currentStateRefreshSubject$:BehaviorSubject<null> = new BehaviorSubject<null>(null);
  currentState$:Observable<AuthState> = this.currentStateRefreshSubject$.pipe(
    switchMap( () => this.isAuthenticated()),
    switchMap( () => from(this.getIdToken())),
    filter( (token:string) => !!token ),
    map( (token:string) => ({token, jwt: <JWT>jwt_decode(token)})),
    distinctUntilChanged((prev,curr) => prev.jwt.account_id === curr.jwt.account_id),
  )

  /**
   * *********************************************************************************
   * METHODS *************************************************************************
   * *********************************************************************************
   */

  constructor(
    private platform: Platform,
    // @Inject(SESSION_STORAGE) private session: VaultService,
    private session: VaultService,
    @Inject(AUTH_0) private config,
  ) { }

  private currentStateForceUpdate() {
    this.currentStateRefreshSubject$.next(null);
  }

  public async initialize(): Promise<void> {
    console.warn('AuthService: setup()');
    return AuthConnect.setup({
      platform: this.platform.is('hybrid') ? 'capacitor' : 'web',
      // logLevel: 'DEBUG',
      logLevel: 'ERROR',
      ...this.config.extraSettings,
    });
  }

  public async acConfig() {
    return AuthConnect.getConfig();
  }
  
  public async login(email?: string, isPasswordless?: boolean, userExists?: boolean) {
    console.log('in login login() function - CONFIG:', this.config);
    Auth0Provider.email = email;
    Auth0Provider.isPasswordless = isPasswordless;
    Auth0Provider.userDoesNotExist = !userExists;

    this.result = await AuthConnect.login(this.provider, this.config);
    console.log('login() result:', this.result);
    await this.session.setSession(this.result);
    this.currentStateForceUpdate();
  }

  public async logout(): Promise<void> {
    if (!this.result) return;
    await AuthConnect.logout(this.provider, this.result);
    await this.session.clear();
    this.result = null;
    this.currentStateForceUpdate();
  }

  async getAccessToken() {
    if (!this.result) return null;
    return this.result.accessToken;
  }

  async getIdToken() {
    if (!this.result) return null;
    return this.result.idToken;
  }

  async getRefreshToken() {
    if (!this.result) return null;
    return this.result.refreshToken;
  }

  async isAuthenticated() {
    // Use your own provider here
    const provider: AuthProvider = new Auth0Provider();
    try {
      const authResult = await this.session.getSession();
      if(!authResult) return false;
      this.result = authResult;
      // If no id token, the login is invalid
      const { idToken } = authResult;
      if (!idToken) throw new Error('No ID Token');
  
      // If token is not expired, user is currently authenticated
      const expired = await AuthConnect.isAccessTokenExpired(authResult);
      if (!expired) return true;
  
      // Token is expired, so try to refresh the session, will throw if fails
      const newAuthResult = await AuthConnect.refreshSession(provider, authResult);
      this.currentStateForceUpdate();
      /**
       * Call your storage provider and save your new authResult object
       */
      await this.session.setSession(newAuthResult);
      return true;
    } catch (e) {
      console.error(e);
      /**
       * Call your storage provider and remove your authResult object
       */
      await this.session.clear()
      return false;
    }
  }


  private async getAuthResult(): Promise<AuthResult | null> {
    return this.session.getSession();
  }

  private async saveAuthResult(authResult: AuthResult | null): Promise<void> {
    if (authResult) {
      await this.session.setSession(authResult);
    } else {
      await this.session.clear();
    }
  }

  async handleLoginCallback(): Promise<boolean> {
    // const params = new URLSearchParams(window.location.hash.replace('#','') );  //for authFlow: 'implicit',
    const params = getQueryParams();
    console.log('params:', params);
    if(params.size===0) return false; //DO NOTHING TO SAVED AUTH STATE IF THERE ARE NO PARMWS
    
    let authResult = null;

    if (params.size > 0) {
      const queryEntries = Object.fromEntries(params.entries());
      console.log('queryEntries:', queryEntries);
      authResult = await AuthConnect.handleLoginCallback(
        queryEntries,
        this.config,
      );
      console.log('authResult:', authResult);
    }
    await this.saveAuthResult(authResult);

    this.currentStateForceUpdate();
    return true;
  }

  async handleLogoutCallback(): Promise<void> {
    await this.saveAuthResult(null);
  }
  
}

export class Auth0Provider extends BaseAuth0Provider {
  static email: string;
  static isPasswordless: boolean = true;
  static userDoesNotExist: boolean = false;

  async authorizeRequest(
    manifest: Manifest,
    options: ProviderOptions,
    config: Pick<
      AuthConnectConfig,
      'ios' | 'android' | 'web' | 'platform' | 'logLevel'
    >
  ) {
    const { url, params } = await super.authorizeRequest(
      manifest,
      options,
      config
    );

    if (Auth0Provider.isPasswordless) {
      params['connection'] = 'email';
    }
    if (Auth0Provider.email) {
      params['login_hint'] = Auth0Provider.email;
    }
    if (Auth0Provider.userDoesNotExist) {
      params['screen_hint'] = 'signup';
    }

    return {
      url,
      params
    };
  }
}





/*
export const isTokenExpired = (token: string) => {
  // console.log("***isTokenExpired function***");

  let decoded: { exp: string };
  try {
    decoded = jwt_decode(token);
    //  throw("asdf");
  } catch (e) {
    console.error('ERROR DECODING TOKEN - RETURNING true FOR EXPIRED', e);
    return false;
  }
  const now: number = Date.now();
  return +decoded?.exp < +`${now}`.substr(0, `${now}`.length - 3);
};

export const debounceFunction = (fn: Function, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};
const debouncedLog = debounceFunction((...args) => console.log(...args), 50);
*/
